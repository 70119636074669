<template>
  <div>
    <!--  Selected search criteria and,  selected Search Criteria-->
    <vx-card class="mb-8">
      <div class="flex mb-2 btn-search justify-start flex-wrap items-center">
        <p>Current Connection : {{ connectionID?connectionID.Value:'' }}</p>
        <vs-button
          @click="connectionPopup = true"
          color="success"
          class="sm:mt-0 mt-2 sm:ml-2 ml-0"
          icon-pack="feather"
          >Change Connection
        </vs-button>
      </div>
      <div class="card-title more-card-title flex justify-between items-center">
        <div class="flex items-center flex-wrap">
          <h2 class="w-auto">Selected Criteria</h2>
          <p class="table-data-entries-details text-left">
            Total Contacts:
            <span>{{ totalContacts }}</span>
          </p>
        </div>
        <!-- Clear Selected search criteria and Contacts -->
        <vs-button
          @click="clearSearchCriteria()"
          color="grey"
          type="border"
          class="grey-btn sm:mt-0 mt-2"
          icon-pack="feather"
          icon="icon-refresh-ccw"
          >{{ LabelConstant.buttonLabelClearSearchCriteria }}
        </vs-button>
      </div>
      <!-- Selected Search Criteria -->
      <div class="vx-row find-people">
        <div class="vx-col w-full">
          <input-tag v-model="tags" @input="testUpdateSelectedTags"></input-tag>
        </div>
      </div>
    </vx-card>
    <vx-card>
      <div class="vx-row find-people">
        <!-- Search by criteria -->
        <div class="vx-col xl:w-1/4 col-cus-12 mb-8 w-full">
          <!-- Search Criteria -->
          <div class="px-0 lead-accordion z-0 search-filters">
            <div class="filter-card border-0">
              <div slot="header" class="filter-header pl-0">
                Filter Contacts by
              </div>
              <div class="filter-list label-mt-2">
                <vs-collapse accordion class="p-0">
                   <!-- System Defined Filters  -->
                  <div class="filter-card px-0">
                    <vs-collapse-item>
                      <div slot="header">System Defined Filters</div>
                      <div class="filter-list" v-for="(tr, indextr) in systemFilterFields" :key="indextr">
                        <label>{{ tr.Display_Name }}</label>
                        <v-select v-if="tr.Search_Values" :v-model="tr.searchInput" :options="tr.Search_Values" multiple class="multiple-select mt-0" />
                        <vs-input  class="w-full mb-2" v-else v-model="tr.searchInput" @blur="searchCriteriaChange()" />
                      </div>
                    </vs-collapse-item>
                  </div>
                  <!-- CRM Fields  -->
                  <div class="filter-card px-0">
                    <vs-collapse-item>
                      <div slot="header">CRM Fields</div>
                      <div class="filter-list" v-for="(tr, indextr) in  crmFilterFields" :key="indextr">
                        <label  >{{ tr.Display_Name }}</label>
                        <vs-input class="w-full mb-2" v-if="!tr.Search_Values" v-model="tr.searchInput" @blur="searchCriteriaChange()" />
                        <v-select v-else :v-model="tr.searchInput" :options="tr.Search_Values" multiple class="multiple-select mt-0" @input="searchCriteriaChange()" />
                      </div>
                    </vs-collapse-item>
                  </div>
                  <!-- Job Criteria -->
            <!-- <div class="filter-card px-0">
              <vs-collapse-item>
                <div slot="header">Job Criteria</div>
                <div class="filter-list">
                  <vs-checkbox v-model="showJobTitleText" >Job Title</vs-checkbox>
                  <div v-if="showJobTitleText">
                    <input-tag :addTagOnBlur="true"></input-tag>
                  </div>
                  <vs-checkbox v-model="showJobLevelOptions" >Job Level</vs-checkbox>
                  <div v-if="showJobLevelOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="jobLevelLabelName"
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showJobFunctionOptions" >Job Function</vs-checkbox>
                  <div v-if="showJobFunctionOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="jobFunctionLabelName"
                      
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showJobStartDateOptions" >Job Start Date </vs-checkbox>
                  <div v-if="showJobStartDateOptions">
                    <v-select 
                      :label="jobStartDateLabelName"
                     
                    />
                  </div>
                  <vs-checkbox >Recent Changed Job</vs-checkbox>
                </div>
              </vs-collapse-item>
            </div> -->
            <!-- Company Criteria -->
            <!-- <div class="filter-card px-0">
              <vs-collapse-item>
                <div slot="header">Company Criteria</div>
                <div class="filter-list">
                  <vs-checkbox v-model="showCompanyNameText" >Company Name</vs-checkbox>
                  <div v-if="showCompanyNameText">
                    <input-tag ></input-tag>
                  </div>
                  <vs-checkbox v-model="showCompanyWebAddressText" >Company Web Address</vs-checkbox>
                  <div v-if="showCompanyWebAddressText">
                    <input-tag  :addTagOnBlur="true"></input-tag>
                  </div>
                  <vs-checkbox v-model="showCompanyIndustryOptions" >Company Industry</vs-checkbox>
                  <div v-if="showCompanyIndustryOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="companyIndustryLabelName"
                      
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showCompanyEmployeeSizeOptions"
                    >Company Employee Size
                  </vs-checkbox>
                  <div v-if="showCompanyEmployeeSizeOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="companyEmployeeSizeLabelName"
                     
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showCompanyRevenueBandsOptions" >
                    Company Revenue Band
                  </vs-checkbox>
                  <div v-if="showCompanyRevenueBandsOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="companyRevenueBandsLabelName"
                     
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showCompanyNAICSOptions" > Company NAICS </vs-checkbox>
                  <div v-if="showCompanyNAICSOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="companyNAICSLabelName"
                     
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showCompanySICSOptions" > Company SIC </vs-checkbox>
                  <div v-if="showCompanySICSOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="companySICSLabelName"
                     
                      multiple
                    />
                  </div>
                </div>
              </vs-collapse-item>
            </div> -->
            <!-- Location Criteria -->
            <!-- <div class="filter-card px-0">
              <vs-collapse-item>
                <div slot="header">Location Criteria</div>
                <div class="filter-list">
                  <vs-checkbox v-model="showContactRegionOptions" > Contact Region</vs-checkbox>
                  <div v-if="showContactRegionOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="contactRegionLabelName"
                      
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showContactCountriesOptions" >Contact Country</vs-checkbox>
                  <div v-if="showContactCountriesOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="contactCountriesLabelName"
                     
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showContactSatesOptions" >Contact State</vs-checkbox>
                  <div v-if="showContactSatesOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="contactStatesLabelName"
                     
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showContactCity" >Contact City </vs-checkbox>
                  <div v-if="showContactCity">
                    <input-tag ></input-tag>
                  </div>
                  <vs-checkbox v-model="showContactZipCode" > Contact Zip </vs-checkbox>
                  <div v-if="showContactZipCode">
                    <input-tag ></input-tag>
                  </div>
                </div>
              </vs-collapse-item>
            </div> -->
            <!-- Personal Criteria -->
            <!-- <div class="filter-card px-0">
              <vs-collapse-item>
                <div slot="header">Personal Criteria</div>
                <div class="filter-list">
                  <vs-checkbox v-model="showPersonalContactName" > Contact Name</vs-checkbox>
                  <div v-if="showPersonalContactName" class="m-3">
                    <vs-input class="mb-2" placeholder="Type first name here"/>
                    <vs-input  placeholder="Type last name here"/>
                  </div>
                  <vs-checkbox v-model="showPersonalContactGenderOptions" >Contact Gender</vs-checkbox>
                  <div v-if="showPersonalContactGenderOptions">
                    <v-select
                      class="multiple-select type-multiselect"
                      :label="personalContactGenderLabelName"
                      
                      multiple
                    />
                  </div>
                  <vs-checkbox v-model="showPersonalContactLastName" >Contact Alma Mater</vs-checkbox>
                  <div v-if="showPersonalContactLastName">
                    <input-tag ></input-tag>
                  </div>
                  <vs-checkbox v-model="showPersonalContactBirthday">Contact Age </vs-checkbox>
                  <div v-if="showPersonalContactBirthday" class="my-3">
                    
                    <vue-numeric-input placeholder="From" :controls="false" v-model="personalContactBirthdayFrom" class="mb-2" ></vue-numeric-input>

                    <vue-numeric-input placeholder="To" :controls="false" v-model="personalContactBirthdayTo" ></vue-numeric-input>
                    
                  </div>
                  <vs-checkbox v-model="showPersonalContactSkills" > Contact Skill </vs-checkbox>
                  <div v-if="showPersonalContactSkills">
                    <input-tag ></input-tag>
                  </div>
                  <vs-checkbox v-model="showPersonalContactFormerEmployer" >Contact Former Employer</vs-checkbox>
                  <div v-if="showPersonalContactFormerEmployer">
                    <input-tag ></input-tag>
                  </div>
                 
                  <vs-checkbox >Personal Email Address Required</vs-checkbox>
                  <vs-checkbox >Cell Phone Required</vs-checkbox>
                </div>
              </vs-collapse-item>
            </div> -->
            
            <!-- List Criteria -->
            <!-- <div class="filter-card px-0">
              <vs-collapse-item>
                <div slot="header">List Criteria</div>
                <div class="filter-list">
                  <vs-checkbox >Primary Contact</vs-checkbox>
                  <vs-checkbox>Professional Email</vs-checkbox>
                  <vs-checkbox >Deterministic Email</vs-checkbox>
                  <vs-checkbox >Phone Number</vs-checkbox>
                </div>
              </vs-collapse-item>
            </div> -->
            <!-- Type Criteria -->
                  <!-- <div class="filter-card px-0">
                    <vs-collapse-item>
                      <div slot="header">Type</div>
                      <div class="filter-list">
                        <vs-checkbox v-model="showSuppressionContactTitles"
                          >Type</vs-checkbox
                        >
                        <div v-if="showSuppressionContactTitles">
                          <input-tag
                            v-model="suppressionContactTitles"
                            :addTagOnBlur="true"
                          ></input-tag>
                        </div>
                      </div>
                    </vs-collapse-item>
                  </div> -->
                  <!-- Sync Status -->
                  <!-- <div class="filter-card px-0">
                    <vs-collapse-item>
                      <div slot="header">Sync Status</div>
                      <div class="filter-list">
                        <vs-checkbox >Status</vs-checkbox
                        >
                      </div>
                    </vs-collapse-item>
                  </div> -->
                </vs-collapse>
              </div>
            </div>
          </div>
          <div class="pt-5 mt-5 flex flex-wrap  items-center">
            <vs-button
              title="Filter"
              color="primary"
              type="filled"
              class="mr-3 float-left flex-1 px-4 my-2"
              @click="searchContacts(1)"
            >Filter</vs-button>
            <vs-button
              color="grey"
              type="border"
              class="grey-btn ml-2 float-left flex-1 px-4 my-2"
              icon-pack="feather"
              icon="icon-refresh-ccw"
              @click="clearSearchCriteria()"
            >Clear</vs-button>
          </div>
          <!-- <div class="pt-5 mt-5 flex flex-wrap items-center">
            <vs-button
              :title="LabelConstant.buttonLabelSearch"
              color="primary"
              type="filled"
              class="mr-1 float-left px-4 my-2"
              >{{ LabelConstant.buttonLabelSearch }}</vs-button
            >
            <vs-button
              title="Save New Search"
              color="success"
              type="filled"
              class="mr-2 float-left px-4 my-2"
              >Save New Search</vs-button
            >
            <a class="mr-1 float-left my-2 btn-link">View Saved Search</a>
          </div> -->
        </div>
        <!--  Contacts table -->
        <div class="vx-col xl:w-3/4 col-cus-12 mb-8 w-full border-left">
          <div class="card-title more-card-title flex align-center">
            <div>
              <h2 class="mb-1">Target Contacts</h2>
              <p class="lg:mb-0 sm:mb-2">
                {{ LabelConstant.infoLableRefineYourSearch }}
              </p>
            </div>
            <div>
              <!-- Record Per page -->
              <v-select class="mr-2 float-left show-tbl-data" label="text" :options="recordList" v-model="numberOfRecords" :reduce="options => options.value" :clearable="false" @input="searchContacts(1)"/>
              <vs-button
                title="Sync"
                color="primary"
                type="filled"
                class="mr-2 float-left"
                @click="targetContactSync()"
                >Sync</vs-button
              >
              <vs-button
                title="Remove"
                color="warning"
                type="filled"
                class="mr-2 float-left"
                @click="warningPopup = true"
              >
                Remove</vs-button
              >
              <!-- <vs-button
                title="Disqualify"
                color="danger"
                type="filled"
                class="mr-2 float-left"
                @click="(warningType = 'disqualify'), (warningPopup = true)"
                >Disqualify
              </vs-button> -->
            </div>
          </div>
          <!-- Companies table  -->
          <div class="common-table pt-0">
            <!-- table -->
            <vs-table
              v-model="rowsSelected"
              multiple
              :data="contacts"
            >
              <template slot="thead">
                <vs-th></vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Company</vs-th>
                <vs-th>Type</vs-th>
                <vs-th>Module</vs-th>
                <vs-th>Sync Status</vs-th>
                <vs-th>Last Modified</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td width="50">
                    <div class="">
                      <vs-button
                        title="Edit"
                        color="primary"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-edit"
                        class="mr-2 float-left"
                      ></vs-button>
                    </div>
                  </vs-td>
                  <vs-td :data="data[indextr].Contact_Name">
                    <a @click="getTargetContactDetails(tr.VisualVisitorID)">{{ data[indextr].Contact_Name }}</a>
                  </vs-td>
                  <vs-td :data="data[indextr].Company_Name">
                    {{ data[indextr].Company_Name }}
                  </vs-td>
                  <vs-td :data="data[indextr].Type">
                    {{ data[indextr].Type }}
                  </vs-td>
                  <vs-td :data="data[indextr].Module">
                    {{ data[indextr].Module }}
                  </vs-td>
                  <vs-td :data="data[indextr].Sync_Status">
                    {{ data[indextr].Sync_Status }}
                  </vs-td>
                  <vs-td :data="data[indextr].Last_Modified">
                    {{ data[indextr].Last_Modified }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <div class="vx-row">
              <div class="vx-col w-full lg:w-full">
                <div class="flex items-center justify-end paginate-row flex-wrap">
                  <paginate
                    ref="companiesPaginate"
                    :page-count="totalPages"
                    :click-handler="searchContacts"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :page-range="3"
                    :first-last-button="true"
                    :first-button-text="'|<'"
                    :last-button-text="'>|'"
                    :container-class="'paginate'"
                  ></paginate>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>

    <!-- select connection popup -->
    <vs-popup class="delete-popup" :active.sync="connectionPopup">
      <template>
        <h2 class="mt-5">Target Contacts</h2>
        <form data-vv-scope="updateConnection">
          <div class="vx-row my-5 px-5 text-left">
            <div class="vx-col w-full">
              Select CRM Connection to view
              <v-select
                v-model="connectionID"
                :options="connectionList"
                label="Value"
                :clearable="false"
              />
            </div>
            <span class="text-danger error-msg">{{ errors.first("updateConnection.connectionID") }}</span>
          </div>
        </form>
      </template>
      <div class="text-center  my-6 py-5">
        <vs-button title="Update" color="primary" type="filled" class="mr-2" @click="updateConnectionId()">{{ LabelConstant.buttonLabelUpdate }}</vs-button>
        <vs-button
          color="grey"
          @click="connectionPopup = false"
          type="border"
          class="ml-4 grey-btn mr-3"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Warning popup -->
    <vs-popup class="delete-popup" :active.sync="warningPopup">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Are you sure?</h4>
          </div>
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button
          title="Delete"
          color="danger"
          type="filled"
          class="mr-2"
          @click="targetContactRemove()"
          >{{ LabelConstant.buttonLabelDelete }}</vs-button
        >
        <vs-button
          color="grey"
          @click="warningPopup = false"
          type="border"
          class="ml-4 grey-btn mr-3"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
    
    <!-- Contact Details popup -->
    <vs-popup
      class="campaign-second popup-width"
      title="Contact Details"
      :active.sync="contactDetailPopup"
    >
      <template>     
          <div class="text-right w-full float-left  mb-8">
              <vs-button
                title="Update"
                color="primary"
                type="filled"
                @click="goToTargetCompany()"       
                >Company Detail</vs-button
              >           
          </div>
        
          <!--Contact Detail  -->

            <vx-card class="job-details-tabs">
              <div class="vx-row find-people">
                <div class="vx-col xl:w-full col-cus-12 mb-6 w-full">
                  <div class="card-title flex justify-start items-center mb-2">
                    <h4 class="m-0 pr-1">Contact Name:</h4>
                    <p>{{ targetContactName }}</p>
                  </div>
                  <div class="">
                    <!-- table -->
                    <table
                      class="
                        comparision-table
                        white-background
                        table
                        w-full
                        bordered
                      "
                    >
                      <thead>
                        <vs-th></vs-th>
                        <vs-th colspan="2"> <h2>VisualVisitor</h2></vs-th>
                        <vs-th colspan="3"> <h2>{{ systemName }}</h2></vs-th>
                      </thead>
                      <thead>
                        <vs-th></vs-th>
                        <vs-th>Field Name</vs-th>
                        <vs-th>Value</vs-th>
                        <vs-th>Issue</vs-th>
                        <vs-th>Field</vs-th>
                        <vs-th>Value</vs-th>
                      </thead>
                      <tbody>
                        <tr v-for="(tr, indextr) in contactDetailsFields" :key="indextr" :data="tr"  :class="tr.Issue?'invalid-data':''">
                          <td class="w-10">
                            <div class="table-action w-10">
                              <vs-button
                                v-if="tr.Issue"
                                title="Edit"
                                color="primary"
                                type="filled"
                                icon-pack="feather"
                                icon="icon-edit"
                                class="mr-1"
                                @click="editRow(indextr)"
                              ></vs-button>
                            </div>
                          </td>
                          <td :class="tr.Read_Only?'light-gray':''">{{ tr.App_Display_Field_Name }}</td>
                          <td :class="tr.Read_Only?'light-gray':''"><vs-input v-if="!tr.disableField"   v-model="tr.App_Value" class="text-danger" :disabled="tr.disableField"  /> <p v-else>{{ tr.App_Value }}</p></td>
                          <td class="text-danger">{{ tr.Issue }}</td>
                          <td class="bg-fourth">{{ tr.CRM_Display_Field_Name }}</td>
                          <td class="bg-fourth">{{ tr.CRM_Value }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </vx-card>
            <div class="text-right w-full float-left mt-6 mb-8">
              <vs-button
                title="Validate"
                color="warning"
                type="filled"
                class="mr-2"
                @click="validateContactDetails()"
                >Validate</vs-button
              >
              <vs-button
                title="Update"
                color="primary"
                type="filled"
                class="mr-2"
                @click="updateContactDetails()"
                >{{ LabelConstant.buttonLabelUpdate }}</vs-button
              >
              <vs-button
                title="Sync Now"
                color="success"
                type="filled"
                class="mr-2"
                @click="syncNowContactDetails()"
                >Sync Now</vs-button
              >
              <vs-button
                color="grey"
                @click="contactDetailPopup = false"
                type="border"
                class="grey-btn mr-3"
                >{{ LabelConstant.buttonLabelCancel }}</vs-button
              >
            </div>     
      </template>
    </vs-popup>
  </div>
</template>
<script >
import Vue from "vue";
import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);
import InputTag from "vue-input-tag";
import { BMedia, BImg, BCardText } from "bootstrap-vue";
import vSelect from "vue-select";
import DateRangePicker from "../../components/filter/DateRangePicker";
export default {
  components: {
    InputTag,
    BMedia,
    BImg,
    BCardText,
    "v-select": vSelect,
    DateRangePicker,
  },
  data: () => ({
    connectionPopup: false,
    contactDetailPopup: false,

    contactDetailsFields:[],
    systemName:null,
    targetContactName:null,
    VVID:null,
    parentCompanyId:0,
    editRowIndex:-1,
    
    contacts:[],
    rowsSelected:[],
    warningPopup: false,
    numberOfRecords:10,
    recordList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "25", value: 25 },
      { text: "50", value: 50 },
      { text: "100", value: 100},
      { text: "500", value: 500 },
      { text: "1000", value: 1000 },
    ],
    connectionID:null,
    connectionList:[],
    totalPages:1,
    totalContacts:0,
    systemFilterFields:[],
    crmFilterFields:[],

    tags:[],
    tagsTempArr:[],
   
   //Location Criteria
    showContactRegionOptions: false,
    showContactCountriesOptions: false,
    showContactSatesOptions: false,
    showContactCity: false,
    showContactZipCode: false,
    //Job Criteria
      showJobTitleText: false,
      showJobLevelOptions: false,
      showJobFunctionOptions: false,
      showJobStartDateOptions: false,

      //Company Criteria
      showCompanyNameText: false,
      showCompanyWebAddressText: false,
      showCompanyIndustryOptions: false,
      showCompanyEmployeeSizeOptions: false,
      showCompanyRevenueBandsOptions: false,
      showCompanyNAICSOptions: false,
      showCompanySICSOptions: false,

    

      //Personal Criteria
      showPersonalContactName: false,
      showPersonalContactGenderOptions: false,
      showPersonalContactLastName: false,
      showPersonalContactBirthday: false,
      fromAgeErrorMessage: null,
      toAgeErrorMessage: null,
      showPersonalContactSkills: false,
      showPersonalContactFormerEmployer: false,
      // showPersonalContactMinimumConnection: false,

    selected: "Leanne",
    options: ["Leanne", "Bret", "baz"],
  }),
  mounted() {
    this.getTargetConnectionIds();
    if(this.$route.query.conId){
        this.connectionPopup = false;   
    }
  },
  methods: {
    //get connection list 
    async getTargetConnectionIds() {
      this.$vs.loading();
      await this.axios
        .get("/ws/TargetContactList/GetConnectionIds")
        .then((response) => {
          let data = response.data;
          this.connectionList = data;
          if(data.length > 1){
            if(!this.$route.query.conId){
              this.connectionPopup = true;
            } else {
              this.connectionID = this.connectionList.find(element => element.Key == this.$route.query.conId);
              this.getSearchFilters();
            }
          } else {
            if(this.$route.query.conId){
              this.connectionID = this.connectionList.find(element => element.Key == this.$route.query.conId);
            } else {
              this.connectionID = data[0];
            }
            this.getSearchFilters();
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //get filters to filter the data 
    async getSearchFilters() {
      this.$vs.loading();
      await this.axios
        .get("/ws/TargetContactList/GetFilters?connection_Id="+this.connectionID.Key)
        .then((response) => {
            let data = response.data;
         // console.log(data);
          data.SystemSearchFields.map(obj => {
            if(obj.Search_Values){
              obj.searchInput = [];
            } else {
              if(this.$route.query.compId && obj.Display_Name == 'Internal Company Id'){
                obj.searchInput = this.$route.query.compId.toString();
              } else {
                obj.searchInput = null;
              }
            }
            return obj;
          });
          this.systemFilterFields = data.SystemSearchFields;
           data.ContactSearchFields.map(obj => {
            if(obj.Search_Values){
              obj.searchInput = [];
            } else {
              obj.searchInput = null;
            }
            return obj;
          });
          this.crmFilterFields = data.ContactSearchFields;
          this.searchCriteriaChange();       
          this.searchContacts(1);
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //search contact api with pagination
    async searchContacts(pageNumber){
      let systemSearch = [];
      this.systemFilterFields.forEach(element => {
        if(element.searchInput){
          systemSearch.push({"Field_Id": element.Field_Id,
          "Search_Values": element.Search_Values?element.searchInput:element.searchInput?[element.searchInput]:[],
          });
         }
      });
      let contactSearch = [];
      this.crmFilterFields.forEach(element => {
        if(element.searchInput){
          contactSearch.push({"Field_Id": element.Field_Id,
          "Search_Values": element.Search_Values?element.searchInput:element.searchInput?[element.searchInput]:[]
          });
        }
      });
      let input = {
        "Connection_Id": this.connectionID.Key,
        "Page": pageNumber,
        "Rows_Per_Page": this.numberOfRecords,
        SystemSearchFields:systemSearch.length?systemSearch:null,
        CompanySearchFields: null,
        ContactSearchFields:contactSearch.length?contactSearch:null,
      };
      this.$vs.loading();
      await this.axios
        .post("/ws/TargetContactList/SearchContacts", input)
        .then((response) => {
          let data = response.data;
          this.contacts = data.Contacts;
          this.totalPages = data.Page_Count;
          this.totalContacts = data.Record_Count;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
        });
    },
    //tags of search criateria function calls when tag is removed
    testUpdateSelectedTags(value){
       let keyArr = this.tagsTempArr.filter((x) => value.indexOf(x) === -1);
        let testStr = keyArr[0];
        let keyStr = keyArr[0].substring(keyArr[0].indexOf(":") + 1);
        this.systemFilterFields.forEach(element => {
           if(element.searchInput){
             if(testStr.includes(element.Display_Name+":")){
               if(element.Search_Values){
                const index = element.searchInput.indexOf(keyStr.trim());
              // const index  = element.searchInput.findIndex(el => el.Value == keyStr.trim());
                element.searchInput.splice(index, 1);
               } else {
                 element.searchInput = "";
               }
             }
           }
        });
         this.crmFilterFields.forEach(element => {
           if(element.searchInput){
             if(testStr.includes(element.Display_Name+":")){
               if(element.Search_Values){
                const index = element.searchInput.indexOf(keyStr.trim());
                // const index  = element.searchInput.findIndex(el => el.Value == keyStr.trim());
                element.searchInput.splice(index, 1);
               } else {
                 element.searchInput = "";
               }
             }
           }
        });
    },
    //when input of search criteria is changed 
    searchCriteriaChange(){
        let arr =[];
        for (let i = 0; i < this.crmFilterFields.length; i++) {
            if(this.crmFilterFields[i].searchInput){
              if(this.crmFilterFields[i].Search_Values){
                 // console.log(this.crmFilterFields[i]);
                for (let j = 0; j < this.crmFilterFields[i].searchInput; j++) {
                  arr.push(this.crmFilterFields[i].Display_Name +":" + this.crmFilterFields[i].searchInput[j]);
                }
              } else {
                arr.push(this.crmFilterFields[i].Display_Name +":" + this.crmFilterFields[i].searchInput);
              }
            }
        }
        for (let i = 0; i < this.systemFilterFields.length; i++) {
              if(this.systemFilterFields[i].searchInput){
              if(this.systemFilterFields[i].Search_Values){
                for (let j = 0; j < this.systemFilterFields[i].searchInput.length; j++) {
                  arr.push(this.systemFilterFields[i].Display_Name +": " + this.systemFilterFields[i].searchInput[j]);
                }
              } else {
                arr.push(this.systemFilterFields[i].Display_Name +": " + this.systemFilterFields[i].searchInput);
              }
            }
        }
        this.tags = arr;
        this.tagsTempArr = arr;
    },
    //clear fields of search criteria 
    clearSearchCriteria(){
      this.crmFilterFields.forEach(element => {
        if(element.Search_Values){
          element.searchInput = [];
        } else {
          element.searchInput = null;
        }
      });
      this.systemFilterFields.forEach(element => {
        if(element.Search_Values){
          element.searchInput = [];
        } else {
          element.searchInput = null;
        }
      });
      this.tags=[];
      this.tagsTempArr = [];
      this.searchContacts(1);
    },
    //update selected connection
    updateConnectionId(){
      this.$validator.validateAll("updateConnection").then(async result => {
        if (result) {
          this.getSearchFilters();
          this.connectionPopup=false
        }
      });
    },
     async targetContactSync() {
      let Ids = [];
      this.rowsSelected.forEach(element => {
        Ids.push(element.VisualVisitorID);
      });
      let input = {
        VisualVisitorID:Ids
      };
      this.$vs.loading();
      await this.axios
        .post("/ws/TargetContactList/SyncContacts", input)
        .then((response) => {
          let data = response.data;
          if(data.Transaction_Successful){
            this.$vs.notify({
              title: "Success",
              text: data.Message,
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
              this.searchContacts(1);
              this.rowsSelected = [];
          } else {
            this.$vs.notify({
              title: "Error",
              text: data.Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
        });
    },
     async targetContactRemove() {
      let Ids = [];
      this.rowsSelected.forEach(element => {
        Ids.push(element.VisualVisitorID);
      });
      let input = {
        Connection_Id:this.connectionID.Key,
        VisualVisitorID:Ids
      };
      this.$vs.loading();
      await this.axios
        .post("/ws/TargetContactList/DeleteContacts", input)
        .then((response) => {
          this.warningPopup = false;
          let data = response.data;
          this.$vs.notify({
            title: "Success",
            text: data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.searchContacts(1);
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
        });
    },
    /*async targetContactDisqualify() {
       let Ids = [];
      this.rowsSelected.forEach(element => {
        Ids.push(element.VisualVisitorID);
      });
      let input = {
        VisualVisitorID:Ids
      };
      this.$vs.loading();
      await this.axios
        .post("/ws/TargetContactList/DisqualifyContacts", input)
        .then((response) => {
          let data = response.data;
          this.warningPopup = false;
          this.warningType = null;
          this.$vs.notify({
            title: "Success",
            text: data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.searchContacts(1);
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
        });
    },*/
    async getTargetContactDetails(id) {
      this.$vs.loading();
      await this.axios
        .get("/ws/TargetContactListDetail/GetTargetContact?visualVisitorID="+id)
        .then((response) => {
          let data = response.data;
          // console.log(data);
          data.JobActivity.map(function (cdf) {
            cdf.disableField = true;
            return cdf;
          });
          this.contactDetailsFields = data.JobActivity;
          this.systemName = data.CRM_Name_Text;
          this.targetContactName = data.Target_Name;
          this.VVID = data.VisualVisitorID;
          this.parentCompanyId = data.Parent_Company_ID;
          this.contactDetailPopup = true;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
     editRow(index){
      this.editRowIndex = index;
      this.contactDetailsFields[index].disableField = false;
    },
    async validateContactDetails(){
     let inputFields = this.contactDetailsFields;
      let appFieldPair = [];
      inputFields.forEach(element => {
        appFieldPair.push({"Key":element.App_API_Field_Name,"Value":element.App_Value});
      });
      let input = {
        "Connection_Id": this.connectionID.Key,
        "VisualVisitorID": this.VVID,
        "App_Field_Pair": appFieldPair
      };
      this.$vs.loading();
      await this.axios
        .post("/ws/TargetContactListDetail/ValidateRecord", input)
        .then((response) => {
          let data = response.data;
         if(data.Transaction_Successful){ 
          this.$vs.notify({
            title: "Success",
            text: data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
         } else {
             this.$vs.notify({
            title: "Erorr",
            text: data.Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
         }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
        });
    },
    async updateContactDetails(){
      let inputFields = this.contactDetailsFields;
      let appFieldPair = [];
      inputFields.forEach(element => {
        appFieldPair.push({"Key":element.App_API_Field_Name,"Value":element.App_Value});
      });
      let input = {
        "Connection_Id": this.connectionID.Key,
        "VisualVisitorID": this.VVID,
        "App_Field_Pair": appFieldPair
      };
      this.$vs.loading();
      await this.axios
        .post("/ws/TargetContactListDetail/UpdateRecord", input)
        .then((response) => {
          let data = response.data;
          if(data.Transaction_Successful){
            this.$vs.notify({
              title: "Success",
              text: data.Message,
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
            this.cancelContactDetails();
            this.searchContacts(1);
          } else {
            this.$vs.notify({
            title: "Erorr",
            text: data.Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
        });
    },
    cancelContactDetails(){
      this.contactDetailPopup = false;
      this.contactDetailsFields = [];
      this.systemName = null;
      this.targetContactName = null;
      this.VVID = null;
      this.parentCompanyId = 0;
    },
    goToTargetCompany(){
      this.contactDetailPopup=false;
      setTimeout(() => {
         this.$router.push({ name: 'TargetCompanyList', query: { conId: this.connectionID.Key, compId:this.parentCompanyId } })
      }, 100);
    },
     async syncNowContactDetails(){
      let inputFields = this.contactDetailsFields;
      let appFieldPair = [];
      inputFields.forEach(element => {
        appFieldPair.push({"Key":element.App_API_Field_Name,"Value":element.App_Value});
      });
      let input = {
        "VisualVisitorID": this.VVID,
        "App_Field_Pair": appFieldPair,
        "Connection_Id": this.connectionID.Key,
      };
      this.$vs.loading();
      await this.axios
        .post("/ws/TargetContactListDetail/SyncRecord", input)
        .then((response) => {
          let data = response.data;
          if(data.Transaction_Successful){
            this.$vs.notify({
              title: "Success",
              text: data.Message,
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
            this.searchContacts(1);
            this.cancelContactDetails();
          } else {
            this.$vs.notify({
            title: "Erorr",
            text: data.Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
        });
    },
  },
};
</script>